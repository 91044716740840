import classNames from 'classnames';
import * as React from 'react';
import { Heading } from '../Heading';
import styles from './Alert.module.scss';

export type AlertType = 'primary' | 'warning' | 'danger';
export type AlertWidth = '100' | '75' | '50';
export type AlertHeaderStyle = 'medium' | 'small';

export interface AlertProps {
	className?: string;
	header: string;
	type: AlertType;
	isLight?: boolean;
	children?: React.ReactNode;
	width?: AlertWidth;
	headerStyling?: AlertHeaderStyle;
}

export const Alert: React.FC<AlertProps> = ({
	className,
	header,
	type,
	isLight = false,
	children,
	width,
	headerStyling,
}: React.PropsWithChildren<AlertProps>) => (
	<div className={classNames(styles.Alert, className)}>
		<div
			className={classNames(styles.Alert_content, styles[`Alert_content___${type}`], styles[`Alert_content___${width ?? '50'}`], {
				[styles.Alert_content___light]: isLight,
			})}
			role="alert"
		>
			<Heading className={styles.Alert_header} style={headerStyling === 'small' ? 'sm' : 'md'} headingLevel="h2">
				{header}
			</Heading>
			{children}
		</div>
	</div>
);
