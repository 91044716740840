import { Alert } from 'designsystem/components/1-atoms/Alert';
import { RichTextFeature } from '../RichText';

interface AlertFeatureProps extends Content.Alert {
	className?: string;
}

export const AlertFeature: React.FC<AlertFeatureProps> = ({ content, className }) => {
	const { header, type, width, isLight, headerStyling } = content?.properties ?? {};
	return (
		<Alert className={className} header={header} type={type} width={width} isLight={isLight} headerStyling={headerStyling}>
			<RichTextFeature content={content} />
		</Alert>
	);
};
